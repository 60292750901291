<template>
	<div v-if="questionnaire">
		<v-card flat class="wr_grey_1">
			<v-card-text>
				<!-- If questionnaire is in draft mode then show form -->
				<v-form
					ref="chooseTopicQuestionnaireForm"
					v-if="isQuestionnaireDrafted"
				>
					<FormGenerator></FormGenerator>
				</v-form>
				<!--
          else show given answers in form
         -->
				<FormResult v-else></FormResult>
			</v-card-text>
		</v-card>

		<!--
			If user close the prioritization dialog then immediately
			open the submission dialog in case if he wants to send to mentor
		-->
		<SelectedManyTopicsDialog
			v-if="many_topics_dialog"
			v-model="many_topics_dialog"
			:dialog="many_topics_dialog"
			@confirm="onAfterManyTopicsConfirmation()"
			@close="(many_topics_dialog = false), (submission_dialog = true)"
		></SelectedManyTopicsDialog>

		<SubmissionConfirmationDialog
			v-if="submission_dialog"
			v-model="submission_dialog"
			:dialog="submission_dialog"
			@close="submission_dialog = false"
			@confirm="onSubmissionConfirm"
		></SubmissionConfirmationDialog>

		<!-- Show finish step dialog  -->
		<StageFinishDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			current_step="app.choose_topic.title"
			@close="dialog = false"
		></StageFinishDialog>
	</div>
</template>

<script>
import { mapState } from "vuex";
import FormGenerator from "@/components/plugins/formGenerator/Index";
import { formValidationMixin } from "@/mixins/questionnaire/formValidaionMixin.js";
import SelectedManyTopicsDialog from "@/components/research/modules/defineQuestion/stages/chooseTopic/alternatives/summary/SelectedManyTopicsDialog.vue";
import SubmissionConfirmationDialog from "@/components/research/modules/defineQuestion/stages/chooseTopic/SubmissionConfirmationDialog.vue";
import FormResult from "@/components/plugins/formResult/Index";
import StageFinishDialog from "@/components/research/modules/_common/stages/FinishDialog";
import { findElIndex } from "@/utils/helpers";
import { questionnaireHelper } from "@/utils/helpers/questionnaire.js";
import { notificationWriteMixin } from "@/mixins/notificationsMixin";

export default {
	name: "ChooseTopicSummaryPage",

	mixins: [formValidationMixin, notificationWriteMixin],

	data() {
		return {
			many_topics_dialog: false,
			submission_dialog: false,
			dialog: false,
		};
	},

	created() {
		this.readyToListenEvents();
	},

	mounted() {
		this.getDataOnMounted();
	},

	components: {
		FormGenerator,
		SelectedManyTopicsDialog,
		SubmissionConfirmationDialog,
		FormResult,
		StageFinishDialog,
	},

	computed: {
		...mapState({
			questionnaire: (state) => state.questionnaire.list,
			current_questionnaire: (state) =>
				state.questionnaire.choose_topic_current_questionnaire,
			process_status: (state) => state.process.process_status,
		}),
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("redirect-to-previous", async (payload) => {
				try {
					this.$loader.start();
					await this.manageRedirectingPrevious();
				} catch (error) {
					this.$announce.error(error);
				} finally {
					this.$loader.stop();
				}
			});
			this.$eventBus.$on("redirect-to-next", async (payload) => {
				try {
					this.$loader.start();
					await this.manageRedirectingNext();
				} catch (error) {
					this.$announce.error(error);
				} finally {
					this.$loader.stop();
				}
			});
			this.$eventBus.$on("finish-choose-topic", async (payload) => {
				await this.onFinishProcess();
			});
		},

		async notifyToMentor() {
			// Get the research question
			let research_topic = await questionnaireHelper.getResearchTopic();
			// If the answer is given, send it to mentor.
			if (research_topic) {
				let response = await this.$store.dispatch("notification/send", {
					recipient: "mentor",
					msg_title: this.$t("app.my_research_topic"),
					msg_content: research_topic.answer,
				});
				// Now, Write this message to firebase
				await this.writeNotification(response);
			}
		},

		async onFinishProcess() {
			try {
				this.$loader.start();

				// Send the research question to mentor
				if (
					this.current_questionnaire == this.$defines.QUESTIONNAIRE.q30 &&
					this.userHasMentor
				) {
					await this.notifyToMentor();
				}

				// Open the finish dialog
				this.dialog = true;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async getDataOnMounted() {
			try {
				this.$loader.start();
				// Initially, get p1 type questionnaire.
				await this.getQuestionnaire(this.$defines.QUESTIONNAIRE.q20);
				// If questionnaire's status is not draft, then decide what to do next.
				if (
					this.questionnaire.status !==
					this.$defines.QUESTIONNAIRE_STATUS_DRAFT_STRING
				) {
					this.handleProcessOne();
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async getQuestionnaire(questionnaire_name = this.current_questionnaire) {
			await this.$store.dispatch("questionnaire/list", {
				questionnaire_name: questionnaire_name,
			});
			this.$store.commit(
				"questionnaire/SET_CHOOSE_TOPIC_CURRENT_QUESTIONNAIRE",
				{
					questionnaire: questionnaire_name,
				},
			);
		},

		async changeQuestionnaireStatus(status) {
			await this.$store.dispatch("questionnaire/submit", {
				questionnaire_id: this.questionnaire.id,
				status: status,
			});
		},

		async manageRedirectingPrevious() {
			// Decide, how to proceed back.
			if (this.current_questionnaire == this.$defines.QUESTIONNAIRE.q20) {
				this.$router.push({
					name: "ChooseTopic",
				});
			} else {
				await this.getQuestionnaire(this.$defines.QUESTIONNAIRE.q20);
			}
			// After setup back questionnaire, make that status "draft" so user can edit.
			await this.changeQuestionnaireStatus(
				this.$defines.QUESTIONNAIRE_STATUS_DRAFT_STRING,
			);
		},

		async manageRedirectingNext() {
			// Check if all child forms are valid or not?
			let valid = await this.isFormValid("chooseTopicQuestionnaireForm");
			if (!valid) {
				throw new Error("app.ntfy.err.fill_required_fields", {
					cause: "werCustom",
				});
			}
			// Before moving next (only if questionnaire is not p3), mark current questionnaire as "approved".
			if (this.current_questionnaire !== this.$defines.QUESTIONNAIRE.q40) {
				await this.changeQuestionnaireStatus(
					this.$defines.QUESTIONNAIRE_STATUS_WAIT_FOR_APPROVAL_STRING,
				);
			}
			// Now, decide what to do next.
			switch (this.current_questionnaire) {
				case this.$defines.QUESTIONNAIRE.q20:
					await this.handleProcessOne();
					break;
				case this.$defines.QUESTIONNAIRE.q40:
					await this.handleProcessThree();
					break;
			}
		},

		async getSingleChoiceAnswerPosition() {
			let result = await this.$store.getters["questionnaire/getByQuestionType"](
				"single_choice",
			);

			return findElIndex(result.question.options, "id", result.formated_answer);
		},

		async handleProcessOne() {
			let answer_pos = await this.getSingleChoiceAnswerPosition();

			/**
			 * The id of option "No" is greater than the id of answer "yes".
			 * That's why "yes" will always be the first on array.
			 * so find the position of answer, if it is on first index
			 * that means user has selected the "yes" answer.
			 *
			 */
			answer_pos === 0
				? await this.getQuestionnaire(this.$defines.QUESTIONNAIRE.q30)
				: await this.getQuestionnaire(this.$defines.QUESTIONNAIRE.q40);
		},

		async handleProcessThree() {
			let answer_pos = await this.getSingleChoiceAnswerPosition();

			/**
			 * The first two options will always be responsible for open the
			 * "many_topics" dialog.
			 * so find the position of answer, if it is on first two indexs
			 * that means user has selected to open the "many_topics" dialog.
			 *
			 */
			if (answer_pos <= 1) {
				this.many_topics_dialog = true;
			} else {
				this.submission_dialog = true;
			}
		},

		async onAfterManyTopicsConfirmation() {
			/**
			 * if user is relay to choose many topics and agree to use tool,
			 * then redirect him to prioritization tool route and set a flag
			 * that he has used he tool once.
			 */
			await this.$store.dispatch("process/setProcessStatus", {
				process_status_id: this.process_status.id,
				prioritization_tool_used: true,
			});

			this.many_topics_dialog = false;

			this.$router.push({
				name: "ChooseTopicPrioritizationTool",
			});
		},

		async onSubmissionConfirm(payload) {
			try {
				this.$loader.start();

				// Approved the questionnaire because message has been sent.
				await this.changeQuestionnaireStatus(
					this.$defines.QUESTIONNAIRE_STATUS_WAIT_FOR_APPROVAL_STRING,
				);

				// Close the dialog
				this.submission_dialog = false;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("redirect-to-previous");
		this.$eventBus.$off("redirect-to-next");
	},
};
</script>
